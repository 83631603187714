




































import { Component, Vue } from "vue-property-decorator";
import { readUserProfile } from "@/store/main/getters";

@Component
export default class UserProfile extends Vue {
  get userProfile() {
    return readUserProfile(this.$store);
  }
}
